<script setup lang="ts">
import type { FooterMenuItem } from "~/composables/useFooter";

const props = defineProps<{
  item: FooterMenuItem;
}>();
</script>

<template>
  <NuxtLink
    :to="props.item.to"
    :href="props.item.href"
    class="app-footer-menu-item"
    :class="props.item.class"
  >
    <slot v-bind="{ props }">
      <span>{{ props.item.title }}</span>
    </slot>
  </NuxtLink>
</template>
