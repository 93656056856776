<script setup lang="ts">
const { isSearchOpen } = useSearch();
</script>

<template>
  <header
    class="app-header sticky top-0 z-30 font-normal py-[0.5625rem] lg:py-5 h-[4.125rem] lg:h-[5.5rem] flex flex-col items-stretch justify-center bg-white select-none"
  >
    <div class="app-header__container container">
      <div
        class="app-header__wrapper flex items-center justify-between gap-4 lg:grid lg:grid-cols-4 lg:gap-10"
      >
        <div
          class="app-header__section app-header__section--start flex items-center gap-4"
        >
          <AppHeaderLogo />
        </div>

        <div
          class="app-header__section app-header__section--center hidden lg:flex items-center justify-end gap-4 col-span-2"
        >
          <AppHeaderMenu />
        </div>

        <div
          class="app-header__section app-header__section--end grow flex items-center justify-end gap-4"
        >
          <ClientOnly>
            <AppHeaderSearch v-show="isSearchOpen" class="grow" />
          </ClientOnly>
          <AppHeaderActions />
        </div>
      </div>
    </div>
  </header>
</template>
