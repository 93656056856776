<script setup lang="ts">
import type { MenuItem } from "~/composables/useMenu";

const route = useRoute();

const props = withDefaults(defineProps<{ item: MenuItem }>(), {});
</script>

<template>
  <NuxtLink
    :to="props.item.to"
    :class="[
      'app-header-menu-item',
      'flex items-center justify-center gap-2',
      'px-4 py-2',
      'cursor-pointer',
      'rounded-tr-2xl rounded-bl-2xl rounded-br-2xl',
      'whitespace-nowrap',
      props.item.class,
      route.path.includes(props.item.to)
        ? `app-header-menu-item--active ${props.item.activeClass}`
        : '',
    ]"
    :active-class="`app-header-menu-item--active ${props.item.activeClass}`"
    :exact-active-class="`app-header-menu-item--exact-active`"
  >
    <slot v-bind="{ props }">
      <span>{{ props.item.label }}</span>
    </slot>
  </NuxtLink>
</template>
