<script setup lang="ts">
const { socialMenu } = useFooter();
</script>

<template>
  <nav class="app-footer-social" aria-label="Media społecznościowe">
    <ul
      class="app-footer-social__wrapper flex flex-col lg:flex-row items-end lg:items-center gap-4"
    >
      <li class="app-footer-social__item" v-for="item in socialMenu">
        <AppFooterSocialItem v-bind="{ item }" />
      </li>
    </ul>
  </nav>
</template>
