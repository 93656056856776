<script setup lang="ts">
defineOgImageComponent("OgImageGeneral");

const { titleTemplate } = useSeo();

const { getSettings } = useSettings();

const { data } = await useAsyncData(
  "settings",
  async () => await getSettings(),
  { immediate: true }
);

const title = computed(() => data.value?.title);

const ogTitle = computed(() => titleTemplate(title.value));

useSeoMeta({ title, ogTitle });

const config = useRuntimeConfig();

useHead({
  meta: config.public.facebook.appId
    ? [
        {
          id: "fb:app_id",
          property: "fb:app_id",
          content: config.public.facebook.appId,
        },
      ]
    : undefined,
});
</script>

<template>
  <div class="layout layout--default">
    <AppHeader />
    <AppMain class="grow flex flex-col items-stretch">
      <slot />
    </AppMain>
    <AppFooter />
  </div>
</template>
