<script setup lang="ts">
const localePath = useLocalePath();

const { currentPalette } = useTheme();
const { getPostTypeSlug } = usePostTypes();

const items = computed<
  {
    to: string;
    title: string;
    class?: string;
    pathClass?: string;
    isActive?: boolean;
  }[]
>(() => [
  {
    to: localePath({ name: "index" }),
    title: "Strina główna",
    class: "bg-blue",
    pathClass: "fill-blue",
    isActive: currentPalette.value.bg === "bg-blue",
  },
  {
    to: localePath({
      name: "type",
      params: { type: getPostTypeSlug(PostType.Inspiration) },
    }),
    title: "Inspiracje",
    class: "bg-rose",
    pathClass: "fill-rose",
    isActive: currentPalette.value.bg === "bg-rose",
  },
  {
    to: localePath({
      name: "type",
      params: { type: getPostTypeSlug(PostType.Professional) },
    }),
    title: "Partnerzy",
    class: "bg-yellow",
    pathClass: "fill-yellow",
    isActive: currentPalette.value.bg === "bg-yellow",
  },
  {
    to: localePath({
      name: "type",
      params: { type: getPostTypeSlug(PostType.Architect) },
    }),
    title: "Architekci",
    class: "bg-darkblue",
    pathClass: "fill-darkblue",
    isActive: currentPalette.value.bg === "bg-darkblue",
  },
  {
    to: localePath({
      name: "type",
      params: { type: getPostTypeSlug(PostType.Relaxation) },
    }),
    title: "Relaks",
    class: "bg-green",
    pathClass: "fill-green",
    isActive: currentPalette.value.bg === "bg-green",
  },
  {
    to: localePath({
      name: "type",
      params: { type: getPostTypeSlug(PostType.Contractor) },
    }),
    title: "Wykonawcy",
    class: "bg-mongoose",
    pathClass: "fill-mongoose",
    isActive: currentPalette.value.bg === "bg-mongoose",
  },
  {
    to: localePath({
      name: "type",
      params: { type: getPostTypeSlug(PostType.Project) },
    }),
    title: "Projekty gotowe",
    class: "bg-copperfield",
    pathClass: "fill-copperfield",
    isActive: currentPalette.value.bg === "bg-copperfield",
  },
]);
</script>

<template>
  <div
    class="app-footer-decoration flex flex-col lg:flex-row items-end lg:items-center gap-5 lg:gap-4"
  >
    <nav class="app-footer-decoration__menu" aria-label="Rodzaje wpisów">
      <ul class="app-footer-decoration__menu-wrapper flex items-center">
        <li
          class="app-footer-decoration__menu-item"
          v-for="(item, index) in items"
        >
          <NuxtLink
            :to="item.to"
            :title="item.title"
            class="w-10 h-10 lg:w-[3.75rem] lg:h-[3.75rem] rounded-full flex items-center justify-center group shadow-[0_0.25rem_0.25rem_0_rgba(0,0,0,0.15)]"
            :class="item.class"
          >
            <svg
              class="w-[1.625rem] lg:w-10 h-auto"
              :class="{
                block: item.isActive,
                hidden: !item.isActive,
              }"
              width="40"
              height="42"
              viewBox="0 0 40 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g :filter="`url(#filter0_i_1981_6491-${index})`">
                <path
                  d="M16.5384 41.2699V33.5968V24.3739L6.94667 14.0137L1.32068 20.2092C0.976961 20.5803 0.785645 21.0854 0.785645 21.6078V39.2872C0.785645 40.3799 1.60603 41.2665 2.61774 41.2665H7.31958V24.3224L10.1147 27.3428V41.2665H16.5417L16.5384 41.2699Z"
                  :class="item.pathClass"
                />
                <path
                  d="M39.2143 30.4904V21.6111C39.2143 21.0854 39.023 20.5837 38.6793 20.2091L21.2954 1.12772C20.5788 0.354563 19.4212 0.354563 18.7046 1.12772L8.89232 11.8831L29.5609 34.2084H19.3369V41.2698H37.3822C38.3939 41.2698 39.2143 40.3833 39.2143 39.2906V34.7204L18.0301 11.8385L19.9887 9.72518L39.2111 30.4904H39.2143Z"
                  :class="item.pathClass"
                />
              </g>
              <defs>
                <filter
                  :id="`filter0_i_1981_6491-${index}`"
                  x="0.785645"
                  y="0.547852"
                  width="38.4286"
                  height="42.7222"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="1.5" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_1981_6491"
                  />
                </filter>
              </defs>
            </svg>
          </NuxtLink>
        </li>
      </ul>
    </nav>

    <p
      class="app-footer-decoration__caption text-2xl leading-6 lg:text-[2rem] lg:leading-[3.3125rem]"
    >
      <span class="italic">codziennie</span>
      <span class="tracking-[0.25rem] font-bold">&emsp14;</span>
      <span class="font-display tracking-[0.25rem]">inspirujemy</span>
    </p>
  </div>
</template>
